export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'lotteryTime',
    label: 'field.lotteryTime',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'posts',
    label: 'field.channel',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'actions',
    label: 'field.actions',
  },
];
