<template>
  <div>
    <load-profile></load-profile>
    <bet-setting-menu></bet-setting-menu>

    <b-card>
      <b-row class="betting-form-container">
        <b-col lg="12">
          <b-form-group :label="$t('field.lotteryName')">
            <b-form-radio-group
              class="button-checkbox"
              v-model="data.lotteryId"
              name="lottery"
              button-variant="outline-primary"
              buttons
            >
              <b-form-radio
                :value="lottery.id"
                v-for="(lottery, index) in lotteries"
                :key="`lottery-${index}-${lottery.id}`"
                buttons
              >
                <span>
                  {{ $i18n.locale == "en" ? lottery.name : lottery.nameKh }}
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <div>
        <b-row v-if="$can('create', resource)">
          <b-col class="text-right">
            <b-button variant="primary" @click="openCreateForm"
              >{{ $t("button.addTime") }}
            </b-button>
          </b-col>
        </b-row>
        <n-table :fields="fields" :items="lotteryTimes" :busy="loading">
          <template #cell(lotteryTime)="data">
            {{
              moment(data.item.name, "HH:mm")
                .locale($i18n.locale)
                .format("h:mm a")
            }}
          </template>

          <template #cell(posts)="data">
            <span
              v-for="(post, index) in data.item.posts"
              :key="`post-${post.id}`"
              v-if="post.status"
            >
              <span v-if="index > 0">,</span> {{ post.post }}
            </span>
          </template>

          <template #cell(status)="data">
            <b-badge pill variant="success" badge-glow v-if="data.item.status">
              {{ $t(`status.openSell`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.closeSell`) }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-if="$can('update', resource)"
                @click="openUpdateForm(data.item)"
                v-b-tooltip.hover
                :title="$t('general.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
      </div>
    </b-card>

    <create-lottery-time
      ref="createLotteryTime"
      @success="show"
    ></create-lottery-time>
    <update-lottery-time
      ref="updateLotteryTime"
      @success="show"
    ></update-lottery-time>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BButton,
  BBadge,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import BetSettingMenu from "@/views/betSetting/BetSettingMenu.vue";
import moment from "moment";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import CreateLotteryTime from "./Create.vue";
import UpdateLotteryTime from "./Update.vue";

const LotteryRepository = Repository.get("lottery");

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BetSettingMenu,
    NTable,
    CreateLotteryTime,
    UpdateLotteryTime,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      lotteries: [],
      key: 1,
      data: {
        lotteryId: null,
      },
      lottery: {},
      lotteryTimes: [],
      loading: false,
      moment,
    };
  },
  watch: {
    "data.lotteryId"(value) {
      if (value) {
        this.lottery = {};
        this.lotteryTimes = [];
        this.key++;
        this.show();
      }
    },
  },
  mounted() {
    this.getLottery();
  },
  methods: {
    getLottery() {
      LotteryRepository.index().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.lotteries = [...data];
          if (!this.data.lotteryId && this.lotteries.length) {
            this.data.lotteryId = this.lotteries[0].id;
          }
        }
      });
    },
    show() {
      if (!this.data.lotteryId) {
        return;
      }
      this.loading = true;
      LotteryRepository.show(this.data.lotteryId)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.lottery = { ...data };
            this.lotteryTimes = [...data.times];
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openCreateForm() {
      this.$refs.createLotteryTime.show(this.lottery);
    },
    openUpdateForm(item) {
      this.$refs.updateLotteryTime.show(this.lottery, item);
    },
    destroy(item) {
      console.log(item);
    },
  },
  setup() {
    const fields = [...TableFields];
    const resource = "bet-setting";

    return {
      fields,
      resource,
    };
  },
};
</script>