var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-create-lottery-time","cancel-variant":"outline-secondary","ok-title":_vm.$t('button.create'),"cancel-title":_vm.$t('button.close'),"centered":"","title":_vm.$i18n.locale == 'en' ? _vm.lottery.name : _vm.lottery.nameKh,"size":"lg"},on:{"ok":_vm.handleOk},scopedSlots:_vm._u([{key:"modal-ok",fn:function(){return [(_vm.loading)?_c('b-spinner',{attrs:{"small":"","label":"Spinning"}}):_c('span',[_vm._v(_vm._s(_vm.$t("button.create")))])]},proxy:true}])},[_c('validation-observer',{ref:"createLotteryTimeForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"createLotteryTimeForm",ref:"formConfirmation",attrs:{"form":_vm.$refs.createLotteryTimeForm,"disabled":invalid},on:{"submit":_vm.submit}},[_c('n-input',{key:("input-" + _vm.key),attrs:{"fields":_vm.formInput},scopedSlots:_vm._u([{key:"posts",fn:function(){return _vm._l((_vm.data.posts),function(post,index){return _c('div',{key:("post-" + index)},[_c('hr'),_c('h5',[_vm._v(_vm._s(post.post))]),_c('b-row',[(post.post != 'Lo')?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"vid":("2DigitPost-" + index),"name":_vm.$t('field.2DigitPost'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.2DigitPost"))+" ")]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.2DigitPost'),"name":("2DigitPost-" + index)},model:{value:(post.postCountTwo),callback:function ($$v) {_vm.$set(post, "postCountTwo", $$v)},expression:"post.postCountTwo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"vid":("3DigitPost-" + index),"name":_vm.$t('field.3DigitPost'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.3DigitPost"))+" ")]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.3DigitPost'),"name":("3DigitPost-" + index)},model:{value:(post.postCountThree),callback:function ($$v) {_vm.$set(post, "postCountThree", $$v)},expression:"post.postCountThree"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"vid":("closeTime-" + index),"name":_vm.$t('field.closeTime'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.closeTime"))+" ")]),_c('n-time-picker',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.closeTime'),"name":("closeTime-" + index),"minute-step":1},model:{value:(post.closeTime),callback:function ($$v) {_vm.$set(post, "closeTime", $$v)},expression:"post.closeTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"vid":("status-" + index),"name":_vm.$t('field.status'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.status"))+" ")]),_c('b-form-checkbox',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":("status-" + index),"name":("status-" + index),"value":true,"unchecked-value":false},model:{value:(post.status),callback:function ($$v) {_vm.$set(post, "status", $$v)},expression:"post.status"}},[_vm._v(" "+_vm._s(_vm.$t("status.openSell"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)})},proxy:true}],null,true),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }