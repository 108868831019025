export default [
  {
    key: 'endTime',
    label: 'field.lotteryTime',
    rules: 'required',
    type: 'time-picker',
    cols: 12,
  },
  {
    key: 'status',
    label: 'status.openSell',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'isResultGen',
    label: 'field.autoGenerateResult',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'posts',
    label: 'field.channel',
    type: '',
    cols: 12,
  },
]
