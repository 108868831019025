<template>
  <b-modal
    id="modal-create-lottery-time"
    cancel-variant="outline-secondary"
    :ok-title="$t('button.create')"
    :cancel-title="$t('button.close')"
    centered
    :title="$i18n.locale == 'en' ? lottery.name : lottery.nameKh"
    @ok="handleOk"
    size="lg"
  >
    <validation-observer ref="createLotteryTimeForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        ref="formConfirmation"
        key="createLotteryTimeForm"
        :form="$refs.createLotteryTimeForm"
        :disabled="invalid"
        @submit="submit"
      >
        <n-input :fields="formInput" v-model="data" :key="`input-${key}`">
          <template #posts>
            <div v-for="(post, index) in data.posts" :key="`post-${index}`">
              <hr />

              <h5>{{ post.post }}</h5>
              <b-row>
                <b-col md="4" v-if="post.post != 'Lo'">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      :vid="`2DigitPost-${index}`"
                      :name="$t('field.2DigitPost')"
                      :rules="`required|integer`"
                    >
                      <label>
                        {{ $t("field.2DigitPost") }}
                      </label>
                      <b-form-input
                        v-model="post.postCountTwo"
                        :placeholder="$t('field.2DigitPost')"
                        :name="`2DigitPost-${index}`"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      :vid="`3DigitPost-${index}`"
                      :name="$t('field.3DigitPost')"
                      :rules="`required|integer`"
                    >
                      <label>
                        {{ $t("field.3DigitPost") }}
                      </label>
                      <b-form-input
                        v-model="post.postCountThree"
                        :placeholder="$t('field.3DigitPost')"
                        :name="`3DigitPost-${index}`"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      :vid="`closeTime-${index}`"
                      :name="$t('field.closeTime')"
                      :rules="`required`"
                    >
                      <label>
                        {{ $t("field.closeTime") }}
                      </label>
                      <n-time-picker
                        v-model="post.closeTime"
                        :placeholder="$t('field.closeTime')"
                        :name="`closeTime-${index}`"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                        :minute-step="1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      :vid="`status-${index}`"
                      :name="$t('field.status')"
                      :rules="``"
                    >
                      <label>
                        {{ $t("field.status") }}
                      </label>
                      <b-form-checkbox
                        :id="`status-${index}`"
                        v-model="post.status"
                        :name="`status-${index}`"
                        :value="true"
                        :unchecked-value="false"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        {{ $t(`status.openSell`) }}
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </template>
        </n-input>
      </n-form-confirmation>
    </validation-observer>

    <template #modal-ok>
      <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      <span v-else>{{ $t("button.create") }}</span>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BSpinner,
} from "bootstrap-vue";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import Repository from "@/repositories/RepositoryFactory";
import NTimePicker from "@/components/NTimePicker.vue";

const LotteryRepository = Repository.get("lottery");

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BSpinner,
    NInput,
    NFormConfirmation,
    NTimePicker,
  },
  data() {
    return {
      loading: false,
      lottery: {},
      key: 1,
      data: {
        lotteryId: null,
        endTime: null,
        status: true,
        isResultGen: true,
        posts: [
          {
            post: "A",
            postCountTwo: 1,
            postCountThree: 1,
            status: true,
            closeTime: "",
          },
          {
            post: "B",
            postCountTwo: 1,
            postCountThree: 1,
            status: true,
            closeTime: "",
          },
          {
            post: "C",
            postCountTwo: 1,
            postCountThree: 1,
            status: true,
            closeTime: "",
          },
          {
            post: "D",
            postCountTwo: 1,
            postCountThree: 1,
            status: true,
            closeTime: "",
          },
          {
            post: "Lo",
            postCountTwo: 0,
            postCountThree: 1,
            status: false,
          },
        ],
      },
    };
  },
  methods: {
    show(lottery) {
      this.lottery = { ...lottery };
      this.data = {
        lotteryId: lottery.id,
        endTime: null,
        status: true,
        isResultGen: true,
        posts: [
          {
            post: "A",
            postCountTwo: 1,
            postCountThree: 1,
            status: true,
            closeTime: "",
          },
          {
            post: "B",
            postCountTwo: 1,
            postCountThree: 1,
            status: true,
            closeTime: "",
          },
          {
            post: "C",
            postCountTwo: 1,
            postCountThree: 1,
            status: true,
            closeTime: "",
          },
          {
            post: "D",
            postCountTwo: 1,
            postCountThree: 1,
            status: true,
            closeTime: "",
          },
          {
            post: "Lo",
            postCountTwo: 0,
            postCountThree: 1,
            status: false,
            closeTime: "",
          },
        ],
      };
      this.key++;
      this.$bvModal.show("modal-create-lottery-time");
    },
    hide() {
      this.$bvModal.hide("modal-create-lottery-time");
    },
    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.createLotteryTimeForm.validate().then((success) => {
        if (success) {
          this.$refs.formConfirmation.confirm();
        }
      });
    },
    submit() {
      this.loading = true;
      LotteryRepository.createTime(this.data)
        .then((response) => {
          this.hide();
          this.$emit("success");
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.createLotteryTimeForm.setErrors(
              error.response?.data?.errors
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const formInput = [...FormInput];

    return {
      formInput,
    };
  },
};
</script>